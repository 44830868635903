// initial state
const state = () => ({
  message: {
    message: "",
    type: "",
  },
});

// getters
const getters = {};

// actions
const actions = {
  setMessage({ commit }, { message, type }) {
    commit("setMessage", { message, type });
  },
};

// mutations
const mutations = {
  setMessage(state, { message, type }) {
    if (type === "error") {
      state.message = {
        message: message?.graphQLErrors?.shift()?.message || message,
        type,
      };
    } else {
      state.message = { message, type };
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
